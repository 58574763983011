import React from "react"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import GatsbyImage from "gatsby-image/index"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const renderCategory = (categories, title, extraClasses = "") => {
  return (
    categories && (
      <div className="col-12 col-sm-4 col-lg-12">
        <h4 className={`font-weight-bold ${extraClasses}`}>{title}</h4>
        {categories.map((node, i) => {
          return (
            <Link
              to={node.uri}
              key={i}
              dangerouslySetInnerHTML={{
                __html: node.name + (categories.length === i + 1 ? "" : ", "),
              }}
            />
          )
        })}
      </div>
    )
  )
}

const Project = ({ data }) => {
  const { nextPage, previousPage, page } = data
  const {
    title,
    featuredImage,
    projects,
    databaseId,
    industries,
    technologies,
    services,
    seo,
    dateGmt,
    modifiedGmt,
    uri,
  } = page

  const {
    categorySectionImage,
    beforeQuoteImage,
    clientQuote,
    mainContent,
    websiteScreenshot1,
    websiteScreenshot2,
  } = projects

  const industryNodes = industries?.nodes?.length ? industries.nodes : null
  const technologyNodes = technologies?.nodes?.length
    ? technologies.nodes
    : null
  const serviceNodes = services?.nodes?.length ? services.nodes : null

  return (
    <Layout
      bodyClass={`fc_projects-template-default single single-fc_projects postid-${databaseId} app-data index-data singular-data single-data single-fc_projects-data`}
    >
      <Seo
        seoData={seo}
        dateGmt={dateGmt}
        modifiedGmt={modifiedGmt}
        uri={uri}
        type="article"
      />

      <article>
        <BackgroundImage
          Tag="header"
          preserveStackingContext
          fluid={featuredImage?.node.remoteFile?.childImageSharp?.fluid}
        >
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="pl-5 pr-5">
                    <h1
                      className="entry-title mt-5"
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <Link
                      to="/contact/"
                      className="btn btn-primary btn-cta btn-primary-cta"
                    >
                      Get in touch
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
        <section className="pt-5 pb-5">
          <div className="container">
            <h2 className="pl-5 pr-5 black-line-after">
              Start your ecommerce journey, <br />
              and find reasons for us to help.
            </h2>
          </div>
        </section>
        <div className="container-fluid categories-section">
          <div className="row">
            {categorySectionImage && (
              <BackgroundImage
                className="col-lg-8 cat-img"
                preserveStackingContext
                fluid={categorySectionImage?.localFile?.childImageSharp?.fluid}
              />
            )}
            <div className="col-lg-4 mt-5 mt-sm-3 mt-lg-0">
              <div className="row">
                {renderCategory(industryNodes, "INDUSTRY")}
                {renderCategory(
                  technologyNodes,
                  "TECH",
                  "mt-5 mt-sm-0 mt-lg-5"
                )}
                {renderCategory(
                  serviceNodes,
                  "SERVICES",
                  "mt-5 mt-sm-0 mt-lg-5"
                )}
              </div>
            </div>
          </div>
        </div>
        {mainContent && (
          <section className="main-content">
            <div className="container">
              <div className="row">
                <div
                  className="col"
                  dangerouslySetInnerHTML={{ __html: mainContent }}
                />
              </div>
            </div>
          </section>
        )}
        {beforeQuoteImage && (
          <BackgroundImage
            id="after-quote-image"
            preserveStackingContext
            fluid={beforeQuoteImage?.localFile?.childImageSharp?.fluid}
          />
        )}
        {clientQuote && (
          <section>
            <div className="container">
              <div className="row">
                <div className="col">
                  <h2
                    className="text-center pr-5 pl-5"
                    dangerouslySetInnerHTML={{ __html: clientQuote }}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
        {(websiteScreenshot1 || websiteScreenshot2) && (
          <div className="container-fluid">
            <div className="row">
              <div className="col d-flex justify-content-center flex-column flex-md-row">
                {websiteScreenshot1 && (
                  <GatsbyImage
                    className="w-100 d-block web-screenshot mr-0 mr-md-3 mb-3 mb-md-0"
                    fluid={
                      websiteScreenshot1?.localFile?.childImageSharp?.fluid
                    }
                  />
                )}
                {websiteScreenshot2 && (
                  <GatsbyImage
                    className="w-100 d-block web-screenshot"
                    fluid={
                      websiteScreenshot2?.localFile?.childImageSharp?.fluid
                    }
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <footer>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                {previousPage && (
                  <Link
                    className="prev-post"
                    to={previousPage.uri}
                    title={previousPage.title}
                  >
                    <BackgroundImage
                      fluid={
                        previousPage.featuredImage?.node.remoteFile
                          ?.childImageSharp?.fluid
                      }
                    >
                      <div className="overlay d-flex align-items-center justify-content-center">
                        <div className="text-center">
                          <h2
                            className="text-uppercase"
                            dangerouslySetInnerHTML={{
                              __html: previousPage.title,
                            }}
                          />
                          <h4 className="h4">View Project</h4>
                        </div>
                      </div>
                    </BackgroundImage>
                  </Link>
                )}
              </div>
              <div className="col-md-4">
                <Link className="view-all" to="/project/">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <h4 className="h4">View All Projects</h4>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                {nextPage && (
                  <Link
                    className="next-post"
                    to={nextPage.uri}
                    title={nextPage.title}
                  >
                    <BackgroundImage
                      fluid={
                        nextPage.featuredImage?.node.remoteFile?.childImageSharp
                          ?.fluid
                      }
                    >
                      <div className="overlay d-flex align-items-center justify-content-center">
                        <div className="text-center">
                          <h2
                            className="text-uppercase"
                            dangerouslySetInnerHTML={{ __html: nextPage.title }}
                          />
                          <h4 className="h4">View Project</h4>
                        </div>
                      </div>
                    </BackgroundImage>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </footer>
      </article>
    </Layout>
  )
}

export default Project

export const query = graphql`
  query project($databaseId: Int!, $nextPage: Int, $previousPage: Int) {
    page: wpProject(databaseId: { eq: $databaseId }) {
      ...ProjectContent
    }
    nextPage: wpProject(databaseId: { eq: $nextPage }) {
      title
      uri
      featuredImage {
        node {
          remoteFile {
            ...Thumbnail
          }
        }
      }
    }
    previousPage: wpProject(databaseId: { eq: $previousPage }) {
      title
      uri
      featuredImage {
        node {
          remoteFile {
            ...Thumbnail
          }
        }
      }
    }
  }
`
